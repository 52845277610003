.tempLoginEmployees {
  display: flex;
  height: 100vh;
  width: 100vw;

  .leftSide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-width: 50%;
    position: relative;
    height: 100%;
    // padding-top: 150px;
    padding: 1rem 0;
    gap: 1rem;

    & > .headerContainer {
      // position: absolute;
      // top: 2.5rem;
      // left: 2.5rem;
      // right: 2.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem 0;
      width: calc(100% - 2rem);
      margin-bottom: auto;

      & > img {
        height: 4rem;
      }

      & > .stepTracker {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 3rem;
        font-size: 0.9rem;
        width: 50%;
        max-width: 320px;

        &::before {
          content: "";
          position: absolute;
          height: 1.5px;
          width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: #e1e1e1;
        }

        & > div {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          font-weight: 500;
          background: #e1e1e1;

          & > .stepTitle {
            position: absolute;
            bottom: -3.5rem;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 0.7rem;
            text-align: center;
          }
        }

        & > .currentStep {
          background: var(--main-color);
          color: white;

          & > .stepTitle {
            color: var(--font-color);
          }
        }
      }
    }

    & > .submitBtn {
      margin-top: auto;
      padding: 0.6rem 2.5rem;
      border-radius: 35px;
      background: var(--main-color);
      color: white;
      font-weight: 500;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: auto;
      margin-bottom: 2rem;
      margin-left: 2rem;
      min-width: 250px;
      transition: all linear 0.3s;

      &:hover {
        scale: 1.05;
      }
    }

    & > .inputFields,
    .userDetailsContainer {
      margin-bottom: auto;
    }

    & > .mainContainer {
      width: 100%;
      border-radius: 20px;
      padding: 0rem 2.5rem;
      padding-bottom: 1rem;
      color: var(--font-color);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
      overflow-y: scroll;
      margin-bottom: auto;

      & > .allProducts {
        display: flex;
        flex-direction: column;
        // gap: 1.2rem;
        width: 100%;
        // overflow-y: scroll;
        padding-right: 10px;
        height: calc(100% - 5rem);
        &::-webkit-scrollbar {
          display: block;
          width: 7px; /* Set the width of the table's scrollbar */
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          height: 7px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: var(
            --font-color
          ); /* Set the color of the scrollbar thumb */
          border-radius: 5px; /* Set the border-radius of the thumb */
          height: 80px;
          transition: all linear 0.3s;
          cursor: pointer;
        }
        &::-webkit-scrollbar-thumb:hover {
          background-color: var(
            --main-color
          ); /* Set the color when the scrollbar thumb is hovered */
        }
        th,
        td {
          padding: 0.6rem 1rem;
          display: flex;
          // justify-content: center;
          align-items: center;
        }
        & > thead {
          background: #f5f5f5;
          // color: var(--main-color);
          display: flex;
          align-items: left;
          th {
            white-space: nowrap;
            font-size: 0.9rem;
          }
          tr {
            display: flex;
            align-items: left;
            width: 100%;

            & > :nth-child(1),
            :nth-child(2) {
              width: 25%;
              text-align: left;
            }
            & > :nth-child(3),
            :nth-child(4) {
              width: 15%;
              text-align: left;
              & > input {
                height: 2rem;
                border: 1px solid white;
                background: var(--main-color);
              }
            }
            & > :last-child {
              width: 20%;
              text-align: center;
              & > input {
                height: 2rem;
                border: 1px solid white;
                background: var(--main-color);
              }
            }
          }
        }
        & > tbody {
          overflow-y: scroll;
          & > tr {
            display: flex;
            width: 100%;
            & > :nth-child(1),
            :nth-child(2) {
              width: 25%;
              text-align: left;
            }
            & > :nth-child(3),
            :nth-child(4) {
              width: 15%;
              text-align: left;
              & > input {
                height: 2rem;
                border: 1px solid white;
                background: var(--main-color);
              }
            }
            & > :last-child {
              width: 20%;
              text-align: center;
              & > input {
                height: 2rem;
                border: 1px solid white;
                background: var(--main-color);
              }
              .submitBtn {
                padding: 0.8rem 1rem;
                border-radius: 35px;
                border: 1px solid white;
                color: white;
                font-size: 0.8rem;
                font-weight: 450;
                white-space: nowrap;
              }
            }

            .learnBtn {
              padding: 0.6rem 1rem;
              border-radius: 35px;
              border: 1px solid #e1e1e1;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 0.85rem;
              white-space: nowrap;
              max-width: 110%;
              transition: all linear 0.3s;

              &:hover {
                background: var(--main-color);
                color: white;
                border-color: var(--main-color);
              }
            }

            .notAllowed {
              pointer-events: none;
              opacity: 0.5;
            }
          }
          & > .dependentTable {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            // margin: 1rem;
            border: 1px solid #e1e1e1;
            // padding: 1rem;
            border-radius: 8px;
            width: calc(100% - 2rem);
            overflow: hidden;
            margin: 1rem auto;

            & > .header {
              display: flex;
              gap: 1rem;
              align-items: center;
              background: #f5f5f5;
              & > div {
                font-weight: 500;
                padding: 0.4rem 10px;
              }
              & > :nth-child(1),
              :nth-child(3),
              :nth-child(4) {
                width: 28.33%;
              }
              & > :nth-child(2) {
                width: 15%;
              }
            }
            & > .eachDependent {
              display: flex;
              gap: 1rem;
              align-items: center;

              & > div {
                padding: 0.4rem 10px;
              }

              & > :nth-child(1),
              :nth-child(3),
              :nth-child(4) {
                width: 28.33%;
              }
              & > :nth-child(2) {
                width: 15%;
                display: flex;
                align-items: center;
              }
              & > :nth-child(4) {
                display: flex;
                align-items: center;
                & > .learnBtn {
                  padding: 0.6rem 1.5rem;
                  border-radius: 35px;
                  border: 1px solid #e1e1e1;
                  cursor: pointer;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 0.85rem;
                  white-space: nowrap;
                  transition: all linear 0.3s;

                  &:hover {
                    background: var(--main-color);
                    color: white;
                    border-color: var(--main-color);
                  }
                }
              }
            }
          }
        }
      }
      & > .submitProductsBtn {
        padding: 0.8rem 3rem;
        border-radius: 35px;
        border: 1px solid white;
        color: white;
        background: var(--main-color);
        cursor: pointer;
        margin-right: auto;
        font-weight: 500;
        font-size: 0.9rem;
        // margin-right: unset;
      }

      & > .notAllowed {
        pointer-events: none;
        opacity: 0.5;
      }

      .drawerContainer {
        position: fixed;
        inset: 0;

        & > .overlayContainer {
          position: absolute;
          inset: 0;
          z-index: 1;
          background: #00000080;
        }

        & > .calculatorContainer {
          width: 600px;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          background: white;
          z-index: 2;
          padding: 2rem;
          overflow-y: scroll;

          & > .title {
            font-size: 1.25rem;
            font-weight: 450;
            margin-bottom: 3rem;
          }

          & > .inputContainer {
            display: flex;
            flex-direction: column;
            gap: 1.6rem;
          }

          & > .amountContainer {
            margin-top: 2rem;
            padding-top: 2rem;
            border-top: 1px solid #e1e1e1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 4rem;

            & > .btnsContainer {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 1rem;
              flex-direction: row;
              padding: 0;

              & > div {
                width: 50%;
                border: 1px solid #e1e1e1;
                border-radius: 8px;
                padding: 0.7rem 0rem;
                font-size: 0.9rem;
                font-weight: 400;
                text-align: center;
                cursor: pointer;
                transition: all linear 0.3s;

                &:hover {
                  background: var(--main-color);
                  color: white;
                }
              }
            }
          }

          .eachInput {
            & > .title {
              font-size: 0.95rem;
              margin-bottom: 10px;
            }

            & > .premiumAmount {
              border: 1px solid #e1e1e1;
              border-radius: 8px;
              padding: 0.7rem 2rem;
              font-size: 0.9rem;
              font-weight: 400;
            }
          }
        }
      }
    }

    & > img {
      height: 4rem;
    }
  }

  .rightSide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 50%;
    padding: 1rem;

    & > .mainContainer {
      width: 100%;
      height: 100%;
      background: var(--main-color);
      border-radius: 20px;
      padding: 3rem 2.5rem;
      padding-bottom: 1rem;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;

      & > .title {
        font-size: 2rem;
        font-weight: 550;
      }

      & > .desc {
        font-size: 1.2rem;
        font-weight: 500;
        text-align: center;
      }

      & > .toggleSections {
        display: flex;
        justify-content: center;
        gap: 1rem;
        width: 100%;

        & > div {
          background: inherit;
          border: 1.5px solid white;
          border-radius: 35px;
          color: white;
          padding: 0.5rem 1.2rem;
          width: max-content;
          font-size: 0.8rem;
          font-weight: 550;
          height: max-content;
          white-space: nowrap;
          text-align: center;
          cursor: pointer;
        }

        & > .selectedToggle {
          color: var(--main-color);
          background: white;
        }
      }

      & > .sectionDetails {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        & > .eachDetail {
          background: rgba(255, 255, 255, 0.6);
          width: 100%;
          padding: 1rem;
          border-radius: 35px;
          font-size: 1rem;
          font-weight: 550;
          line-height: 1.6;
          display: flex;
          gap: 1.5rem;
          align-items: center;

          & > img {
            height: 100%;
            object-fit: contain;
          }

          & > span {
            color: var(--main-color);
          }
        }
      }

      & > .allInsurance {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        width: 100%;
        overflow-y: scroll;
        padding-right: 10px;

        &::-webkit-scrollbar {
          display: block;
          width: 7px; /* Set the width of the table's scrollbar */
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          height: 7px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(
            --font-color
          ); /* Set the color of the scrollbar thumb */
          border-radius: 5px; /* Set the border-radius of the thumb */
          height: 80px;
          transition: all linear 0.3s;
          cursor: pointer;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: var(
            --main-color
          ); /* Set the color when the scrollbar thumb is hovered */
        }

        & > .eachInsurance {
          margin: 1rem 0;
          width: 100%;

          & > .title {
            font-weight: 520;
            margin: 1rem 0;
          }

          & > .boxContainer {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding: 1rem;
            border-radius: 10px;
            background: #ffffff99;
            color: var(--main-color);
            padding-bottom: 1.5rem;

            & > .eachItem {
              font-size: 0.9rem;
              font-weight: 520;

              & > .title {
                margin: 0.5rem 0;
              }

              & > .valueItem {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0.6rem 1rem;
                background: #ffffff99;
                border-radius: 10px;

                & > span {
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
      }

      & > .allProducts {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        width: 100%;
        // overflow-y: scroll;
        padding-right: 10px;
        height: calc(100% - 13rem);

        &::-webkit-scrollbar {
          display: block;
          width: 7px; /* Set the width of the table's scrollbar */
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          height: 7px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(
            --font-color
          ); /* Set the color of the scrollbar thumb */
          border-radius: 5px; /* Set the border-radius of the thumb */
          height: 80px;
          transition: all linear 0.3s;
          cursor: pointer;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: var(
            --main-color
          ); /* Set the color when the scrollbar thumb is hovered */
        }

        th,
        td {
          padding: 0.6rem 1rem;
          display: flex;
          // justify-content: center;
          align-items: center;
        }

        & > thead {
          background: rgba(255, 255, 255, 0.4);
          color: var(--main-color);
          display: flex;
          align-items: left;

          & > :nth-child(1),
          :nth-child(2) {
            width: 25%;
            text-align: left;
          }

          & > :nth-child(3),
          :nth-child(4) {
            width: 15%;
            text-align: left;
            & > input {
              height: 2rem;
              border: 1px solid white;
              background: var(--main-color);
            }
          }

          & > :last-child {
            width: 20%;
            text-align: center;

            & > input {
              height: 2rem;
              border: 1px solid white;
              background: var(--main-color);
            }
          }
        }

        & > tbody {
          overflow-y: scroll;
          & > tr {
            display: flex;
            width: 100%;

            & > :nth-child(1),
            :nth-child(2) {
              width: 25%;
              text-align: left;
            }

            & > :nth-child(3),
            :nth-child(4) {
              width: 15%;
              text-align: left;
              & > input {
                height: 2rem;
                border: 1px solid white;
                background: var(--main-color);
              }
            }

            & > :last-child {
              width: 20%;
              text-align: center;

              & > input {
                height: 2rem;
                border: 1px solid white;
                background: var(--main-color);
              }

              .submitBtn {
                padding: 0.8rem 1rem;
                border-radius: 35px;
                border: 1px solid white;
                color: white;
                font-size: 0.8rem;
                font-weight: 450;
                white-space: nowrap;
              }
            }
          }

          & > .dependentTable {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            margin: 1rem;
            border: 1px solid white;
            padding: 1rem;
            border-radius: 7px;
            width: 400px;

            & > .title {
              font-size: 1rem;
              text-decoration: underline;
              font-weight: 500;
              margin-bottom: 0.25rem;
            }

            & > .eachDependent {
              display: flex;
              gap: 1rem;
              align-items: center;

              & > :nth-child(1) {
                width: 80%;
              }

              & > :nth-child(2) {
                width: 20%;
              }
            }
          }
        }
      }

      & > .overlayContainer {
        background: #00000080;
        position: fixed;
        inset: 0;
        z-index: 99;

        & > .notificationContainer {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: white;
          border-radius: 10px;
          padding: 2rem;
          display: flex;
          flex-direction: column;
          gap: 2.5rem;
          width: 70%;
          max-width: 800px;
          height: 85%;
          overflow-y: scroll;
          color: var(--font-color);
          // aspect-ratio: 1.3/1;

          & > img {
            height: 3rem;
            object-fit: contain;
            margin-bottom: 1.5rem;
          }

          & > .title {
            font-size: 1.2rem;
            font-weight: 500;
            text-align: center;
          }

          & > .message {
            font-size: 1rem;
            line-height: 1.45;
            text-align: left;
            color: var(--font-color);
            // max-width: 380px;
          }

          & > .allTerms {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            & > .eachTerm {
              display: flex;
              gap: 12px;
              align-items: center;
              font-size: 0.9rem;
            }
          }

          & > .notice {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            font-size: 0.95rem;

            & > .title {
              font-weight: 510;
            }

            & > .message {
              line-height: 1.6;
            }
          }

          & > .btnsContainer {
            display: flex;
            gap: 10px;
            margin: 0 auto;
            flex-direction: row;
            // width: max-content;

            & > div {
              width: 50%;
            }

            & > .coloredBtn,
            .closeBtn {
              padding: 0.8rem 1.5rem;
              border-radius: 10px;
              background: var(--main-color);
              color: white;
              font-weight: 510;
              text-align: center;
              cursor: pointer;
            }

            & > .closeBtn {
              background: #000000;
            }

            & > .notAllowed {
              pointer-events: none;
              opacity: 0.5;
            }
          }
        }
      }

      & > .submitProductsBtn {
        padding: 0.8rem 2rem;
        border-radius: 35px;
        border: 2px solid white;
        color: white;
        cursor: pointer;
        margin-left: auto;
        font-weight: 500;
        font-size: 0.8rem;
        margin-right: unset;
      }

      & > .transparentBtn {
        color: white;
        margin: 0;
        transition: all linear 0.3s;

        &:hover {
          color: var(--main-color);
          background: white;
        }
      }

      & > .selectedProducts {
        color: white;
        height: 100%;
        width: 100%;

        & > .title {
          font-size: 2rem;
          font-weight: 510;
          margin-bottom: 2rem;
        }

        & > .allProducts {
          font-weight: 500;
          font-size: 1.1rem;
          margin-left: 1.5rem;
          list-style-position: inside;

          & > li {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }

    & > .successContainer {
      background: white;
      width: 45%;
      height: 90%;
      border-radius: 20px;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;

      & > img {
        height: 6rem;
      }

      & > .title {
        font-size: 1.5rem;
        font-weight: 520;
      }

      & > ol {
        list-style: decimal;
        font-size: 1rem;
      }

      & > .submitBtn {
        margin-top: 2rem;
        padding: 0.6rem 2.5rem;
        border-radius: 35px;
        background: var(--main-color);
        color: white;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: max-content;
        transition: all linear 0.3s;

        &:hover {
          scale: 1.05;
        }
      }
    }
  }

  .inputFields {
    width: 60%;
    max-width: 400px;

    .conditions {
      width: 100%;
      margin-top: 1.5rem;
      background: #fffdf2;
      border-radius: 15px;
      display: flex;
      flex-direction: column;

      .eachCondition {
        display: flex;
        width: 100%;
        padding: 10px 23px;
        align-items: center;
        justify-content: space-between;
        font-size: 0.75rem;
        font-weight: 450;
        .indicator {
          width: 7px;
          height: 7px;
          background: rgba(220, 48, 39, 0.37);
          border: none;
          border-radius: 50%;
        }
      }
    }

    & > .requirmentsBtn {
      text-align: center;
      margin-top: 2rem;
      text-decoration: underline;
      font-size: 1rem;
      cursor: pointer;
      transition: all linear 0.3s;

      &:hover {
        scale: 1.05;
      }
    }

    & > .backBtn {
      width: 100%;
      padding: 0.75rem 1rem;
      color: var(--font-color);
      border: 1px solid #e5e5e5;
      font-weight: 500;
      border-radius: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.2rem;
      cursor: pointer;
      font-size: 1.2rem;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .userDetailsContainer {
    padding: 1rem;
    border-radius: 10px;
    background: #f5f5f5;
    overflow-y: auto;
    width: calc(100% - 2rem);
    // height: -webkit-fill-available;
    // margin: auto;

    & > .title {
      font-size: 0.9rem;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem 0;
    }

    & > .eachDetails {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 2rem;

      & > .threeDivContainer {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;

        & > :nth-child(1) {
          width: 50%;
        }

        & > :nth-child(2) {
          width: 25%;

          & > div {
            position: relative;
            & > input {
              margin-top: 0;
            }
          }

          & > input {
            margin-top: 0;
          }
        }

        & > :nth-child(3) {
          width: 25% !important;

          & > input {
            margin-top: 0;
          }
        }

        & > .ageBox {
          padding: 1rem 1.5rem;
          border-radius: 10px;
          border: 1px solid rgba(229, 229, 229, 0.4);
          font-size: 0.8rem;
          background: #ffffff;
        }
      }

      & > .twoDivContainer {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;

        & > :nth-child(1) {
          width: 50%;
        }

        & > :nth-child(2) {
          width: 50%;
        }
      }

      & > .singleDivContainer {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;

        & > :nth-child(1) {
          width: 30%;
        }

        & > .ageBox {
          padding: 1rem 1.5rem;
          border-radius: 10px;
          border: 1px solid rgba(229, 229, 229, 0.4);
          font-size: 0.8rem;
          background: #ffffff;
        }
      }
    }

    & > .submitBtn {
      margin-top: auto;
      padding: 0.6rem 2.5rem;
      border-radius: 35px;
      background: var(--main-color);
      color: white;
      font-weight: 500;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: auto;
      margin-bottom: 2rem;
      width: max-content;
      transition: all linear 0.3s;

      &:hover {
        scale: 1.05;
      }
    }
  }

  .btnsContainer {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    // margin-top: auto;
    width: 100%;
    padding-left: 1rem;

    & > div {
      width: 250px;
    }
  }

  .transparentBtn {
    padding: 0.6rem 2.5rem;
    border-radius: 35px;
    border: 1px solid #e1e1e1;
    color: var(--main-color);
    font-weight: 500;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    // margin-left: 2rem;
    min-width: 250px;
    color: var(--font-color);
    transition: all linear 0.3s;

    &:hover {
      scale: 1.05;
    }
  }

  .submitBtn {
    padding: 0.6rem 2.5rem;
    border-radius: 35px;
    background: var(--main-color);
    color: white;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    // margin-left: 2rem;
    width: max-content;
    transition: all linear 0.3s;

    &:hover {
      scale: 1.05;
    }
  }

  .input1 {
    width: 100%;
    height: 7%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 35px;
    padding: 1rem 3rem;
    font-size: 1.9vh;
    input {
      border: none;
      width: 100%;
      &::placeholder {
        font-size: 1.9vh;
      }
    }
    .closeImg {
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
      }
    }
    .indicator {
      width: 10px;
      height: 10px;
      background: #dc3027;
      border: none;
      border-radius: 50%;
    }
    .eyeImg {
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .conditions {
    width: 50%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    background: #fffdf2;
    border-radius: 15px;
    display: flex;
    flex-direction: column;

    .eachCondition {
      display: flex;
      width: 100%;
      padding: 15px 23px;
      align-items: center;
      justify-content: space-between;
      font-size: 0.9rem;
      font-weight: 450;
      .indicator {
        width: 10px;
        height: 10px;
        background: rgba(220, 48, 39, 0.37);
        border: none;
        border-radius: 50%;
      }
    }
  }

  .singleInputContainer {
    width: 100%;
    position: relative;

    & > svg {
      position: absolute;
      font-size: 1.6rem;
      color: #e5e5e5;
      top: 50%;
      right: 15px;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }

  .input2 {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 35px;
    padding: 1rem 2rem;
    font-size: 1.9vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
      border: none;
      width: 85%;
      &::placeholder {
        font-size: 1.9vh;
      }
    }
    .eyeImg {
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .forgotPassword {
    background: white;
    -webkit-background-clip: text;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 1.9vh;
    width: 50%;
    text-align: right;

    div {
      color: var(--font-color);
      cursor: pointer;
      background: white;
      -webkit-background-clip: text;
      background-clip: text;
      text-fill-color: transparent;
      &:hover {
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .loginButton {
    background: var(--main-color);
    width: 100%;
    padding: 0.75rem 1rem;
    color: white;
    font-weight: 500;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    cursor: pointer;
    font-size: 1.2rem;
    transition: all ease 0.2s;

    &:hover {
      transform: scale(1.05);
    }
  }

  .buttonDiv {
    display: flex;
    width: 50%;
    margin-top: 1rem;
    justify-content: space-between;
    .gbBtn {
      width: 49%;
      padding: 3%;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 1.9vh;
      color: var(--font-color);
      border: 1px solid #e5e5e5;
      font-weight: 600;
      &:hover {
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .loginButtonDisabled {
    background: linear-gradient(90deg, #81cbd7 0%, #3d4394 100%);
    width: 50%;
    padding: 2%;
    color: white;
    font-weight: 700;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 85px;
    cursor: pointer;
    opacity: 0.5;
    font-size: 1.9vh;
  }

  .googleLogin {
    width: 50%;
    padding: 2%;
    font-weight: 600;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--font-color);
    font-size: 1.9vh;
    cursor: pointer;
    transition: all ease 0.2s;

    &:hover {
      transform: scale(1.05);
    }
  }

  .rightlabel {
    background: linear-gradient(90deg, #3d4394 0%, #81cbd7 91.36%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 600;
    font-size: 2vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .rightCard {
    padding: 4% 4%;
    margin-top: 4%;
    border: double 1px transparent;
    border-radius: 25px;
    background-image: linear-gradient(white, white),
      radial-gradient(circle at top left, #81cbd7, #3d4394);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    height: 18%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover {
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }
  }

  .rightCardnoHover {
    padding: 4% 4%;
    margin-top: 4%;
    border: double 1px transparent;
    border-radius: 25px;
    background-image: linear-gradient(white, white),
      radial-gradient(circle at top left, #81cbd7, #3d4394);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }

  .rightCardTitle {
    background: linear-gradient(90deg, #3d4394 0%, #81cbd7 91.36%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 600;
    font-size: 2vh;
  }
  .rightCardSubTitle {
    font-weight: 400;
    font-size: 1.6vh;
    color: var(--font-color);
    margin-top: 2%;
    line-height: 25px;
  }
  .rightCardActive {
    padding: 4% 4%;
    margin-top: 4%;
    border: double 1px transparent;
    border-radius: 25px;
    background-image: linear-gradient(white, white),
      radial-gradient(circle at top left, #81cbd7, #3d4394);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    height: 18%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover {
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }
  }

  .options {
    height: 75px;
    display: flex;
    align-items: center;
    padding-left: 35px;
    border: 0.5px solid #e5e5e5;
    margin-bottom: 4%;
    border-radius: 15px;
    font-size: 1.7vh;
    &:hover {
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }
  }

  .optionsDisabled {
    height: 75px;
    display: flex;
    align-items: center;
    padding-left: 35px;
    border: 0.5px solid #e5e5e5;
    margin-bottom: 4%;
    border-radius: 15px;
    font-size: 1.7vh;
  }

  // Mobile UI Styles

  .mobileLogo {
    border-bottom: 1px solid #e5e5e5;
    padding: 5% 20%;
    background-color: white;
    height: 9vh;
    margin-bottom: 10vh;
    img {
      width: 100%;
    }
  }

  .stepContainer {
    height: 65vh;
    position: relative;
    .AffiliatePrompt {
      width: 100%;
      display: flex;
      gap: 10px;
      padding: 1rem 1.5rem;
      background: #fffdf2;
      border-radius: 15px;
      margin-bottom: 2vh;
      font-size: 0.85rem;
    }
  }
  .pageTitle {
    font-size: 2vh;
    font-weight: 600;
    color: var(--font-color);
    // margin-top: 10%;
    margin-bottom: 10%;
  }
  .optionCards {
    display: flex;
    align-items: center;
    margin-bottom: 5%;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    height: 84px;
    padding-left: 30px;
    font-size: 1.8vh;
    font-weight: 600;
    input {
      border: none;
      width: 90%;
      height: 100%;
    }
  }
  .buttonWrapper {
    position: absolute;
    bottom: 20px;
    width: 100%;
    // background-color: red;
  }
  .buttonWrapper1 {
    margin-top: 10vh;
  }
  .backButton {
    background-color: var(--main-color);
    border-radius: 15px;
    width: 100%;
    height: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
    font-size: 20px;
  }
  .continueButton {
    background: var(--font-color);
    border-radius: 15px;
    width: 100%;
    height: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .loadingComponent {
    position: absolute;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background-color: black;
    opacity: 0.4;
  }
  .redDot {
    width: 12px;
    height: 12px;
    background-color: red;
    border-radius: 50%;
  }

  .greenDot {
    width: 12px;
    height: 12px;
    background-color: green;
    border-radius: 50%;
  }
  .passwordChecklist {
    background-color: #fffdf2;
    border-radius: 15px;
    padding: 20px;
    position: relative;

    .eachChecklist {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .indicator {
        width: 10px;
        height: 10px;
        background: rgba(220, 48, 39, 0.37);
        border: none;
        border-radius: 50%;
      }
    }
  }
  .checklistLabel {
    text-decoration: underline;
  }
  .otpInputWrapper {
    width: 75%;
    justify-content: space-between;
    > div {
      flex: 0 0 22%;
      height: 60px;
      .otpInput {
        width: 80% !important;
        height: 100%;
        border: 2px solid #e5e5e5;
        border-radius: 8px;
        font-size: 28px;
      }
    }
  }
}

.eachInputPickOne {
  & > .title {
    font-size: 0.9rem;
  }

  & > .selectOneAllOptions {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    font-size: 1rem;
    margin-top: 0.5rem;

    & > div {
      display: flex;
      font-size: 0.9rem;
      text-align: center;
      width: max-content;
      padding: 0.75rem 2rem;
      cursor: pointer;
      font-size: 0.9rem;
      background: white;
      font-weight: 400;
      border: 1px solid #e1e1e1;
      transition: all linear 0.3s;

      &:hover {
        color: white;
        border: 1px solid var(--main-color);
        background: var(--main-color);
      }
    }

    & > .selectedOption {
      background: var(--main-color);
      color: white;
      border-color: var(--main-color);
    }
  }
}

.overlayContainer {
  background: #00000080;
  position: fixed;
  inset: 0;
  z-index: 99;

  & > .notificationContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 10px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    width: 70%;
    max-width: 800px;
    height: 85%;
    overflow-y: scroll;
    color: var(--font-color);
    // aspect-ratio: 1.3/1;

    & > img {
      height: 3rem;
      object-fit: contain;
      margin-bottom: 1.5rem;
    }

    & > .title {
      font-size: 1.2rem;
      font-weight: 500;
      text-align: center;
    }

    & > .message {
      font-size: 1rem;
      line-height: 1.45;
      text-align: left;
      color: var(--font-color);
      // max-width: 380px;
    }

    & > .allTerms {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      & > .eachTerm {
        display: flex;
        gap: 12px;
        align-items: center;
        font-size: 0.9rem;
      }
    }

    & > .notice {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      font-size: 0.95rem;

      & > .title {
        font-weight: 510;
      }

      & > .message {
        line-height: 1.6;
      }
    }

    & > .btnsContainer {
      display: flex;
      gap: 10px;
      margin: 0 auto;
      flex-direction: row;
      // width: max-content;

      & > div {
        width: 50%;
      }

      & > .coloredBtn,
      .closeBtn {
        padding: 0.8rem 1.5rem;
        border-radius: 10px;
        background: var(--main-color);
        color: white;
        font-weight: 510;
        text-align: center;
        cursor: pointer;
      }

      & > .closeBtn {
        background: #000000;
      }

      & > .notAllowed {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
}

.dependentsAddition {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & > .eachDependent {
    display: flex;
    flex-direction: column;
    gap: 7px;
    & > .title {
      font-size: 1.1rem;
      font-weight: 400;
    }
    & > .dependentDetails {
      background: #f5f5f5;
      border: 1px solid #e5e5e5;
      border-radius: 8px;
      padding: 1.2rem;

      & > .dependentDetailsContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
      }

      & > .createBtn {
        font-size: 0.9rem;
        padding: 0.5rem 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 35px;
        color: white;
        background: var(--main-color);
        cursor: pointer;
        width: max-content;
        margin-top: 1rem;
        outline: none;
        border: none;
        transition: all linear 0.2s;

        &:hover {
          scale: 1.05;
        }
      }
    }
  }
  & > .addChild {
    font-size: 1.1rem;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
    margin: 1rem 0;
  }
}

@media only screen and (max-width: 768px) {
  .tempLoginEmployees {
    & > .leftSide {
      gap: 1.5rem;
      margin: auto;
      width: 100%;
      padding-top: 0;

      & > .headerContainer {
        flex-direction: column;
        gap: 2rem;
        margin-bottom: unset;

        & > .stepTracker {
          width: 75%;
          // display: none;
        }
      }

      & > .dependentsAddition {
        padding: 0 1rem;
      }

      & > img {
        margin-top: 8rem;
      }

      & > .inputFields {
        width: 95%;
        max-width: 380px;
        margin: 0 auto;
      }

      & > .userDetailsContainer {
        background: none;
        padding: 1rem 0;

        & > .eachDetails {
          & > .threeDivContainer,
          .twoDivContainer {
            flex-direction: column;

            & > div {
              width: 100% !important;
            }
          }
        }

        & > .allProducts {
          display: flex;
          flex-direction: column;
          gap: 1.2rem;
          width: 100%;
          // overflow-y: scroll;
          padding-right: 10px;
          height: calc(100% - 13rem);

          &::-webkit-scrollbar {
            display: block;
            width: 7px; /* Set the width of the table's scrollbar */
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            height: 7px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: var(
              --font-color
            ); /* Set the color of the scrollbar thumb */
            border-radius: 5px; /* Set the border-radius of the thumb */
            height: 80px;
            transition: all linear 0.3s;
            cursor: pointer;
          }

          &::-webkit-scrollbar-thumb:hover {
            background-color: var(
              --main-color
            ); /* Set the color when the scrollbar thumb is hovered */
          }

          th,
          td {
            padding: 0.6rem 1rem;
            display: flex;
            // justify-content: center;
            align-items: center;
          }

          & > thead {
            background: rgba(255, 255, 255, 0.4);
            color: var(--main-color);
            display: flex;
            align-items: left;

            & > :nth-child(1),
            :nth-child(2) {
              width: 25%;
              text-align: left;
            }

            & > :nth-child(3),
            :nth-child(4) {
              width: 15%;
              text-align: left;
              & > input {
                height: 2rem;
                border: 1px solid white;
                background: var(--main-color);
              }
            }

            & > :last-child {
              width: 20%;
              text-align: center;

              & > input {
                height: 2rem;
                border: 1px solid white;
                background: var(--main-color);
              }

              .submitBtn {
                padding: 0.8rem 1rem;
                border-radius: 35px;
                border: 1px solid white;
                color: white;
                font-size: 0.8rem;
                font-weight: 450;
                white-space: nowrap;
              }
            }
          }

          & > tbody {
            overflow-y: scroll;
            & > tr {
              display: flex;
              width: 100%;

              & > :nth-child(1),
              :nth-child(2) {
                width: 25%;
                text-align: left;
              }

              & > :nth-child(3),
              :nth-child(4) {
                width: 15%;
                text-align: left;
                & > input {
                  height: 2rem;
                  border: 1px solid white;
                  background: var(--main-color);
                }
              }

              & > :last-child {
                width: 20%;
                text-align: center;

                & > input {
                  height: 2rem;
                  border: 1px solid white;
                  background: var(--main-color);
                }

                .submitBtn {
                  padding: 0.8rem 1rem;
                  border-radius: 35px;
                  border: 1px solid white;
                  color: white;
                  font-size: 0.8rem;
                  font-weight: 450;
                  white-space: nowrap;
                }
              }
            }

            & > .dependentTable {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              margin: 1rem;
              border: 1px solid white;
              padding: 1rem;
              border-radius: 7px;
              width: 400px;

              & > .title {
                font-size: 1rem;
                text-decoration: underline;
                font-weight: 500;
                margin-bottom: 0.25rem;
              }

              & > .eachDependent {
                display: flex;
                gap: 1rem;
                align-items: center;

                & > :nth-child(1) {
                  width: 80%;
                }

                & > :nth-child(2) {
                  width: 20%;
                }
              }
            }
          }
        }

        & > .overlayContainer {
          background: #00000080;
          position: fixed;
          inset: 0;
        }

        & > .title {
          font-weight: 500;
          font-size: 0.9rem;
          margin: 1rem 0;
        }
      }

      & > .mainContainer {
        padding: 0 0.5rem;
      }
    }
    & > .rightSide {
      display: none;

      & > .successContainer {
        width: 100%;
        height: 100%;

        & > img {
          height: 4.5rem;
        }
      }
    }
  }

  .calculatorContainer {
    width: 90% !important;
  }

  .mainContainer {
    .allProducts {
      tbody {
        .dependentTable {
          font-size: 0.8rem !important;
          margin: 1rem 0 !important;
          width: 100% !important;

          .learnBtn {
            padding: 0.6rem 5px !important;
          }
        }
      }
      tr {
        & > :nth-child(1) {
          width: 22% !important;
          font-size: 0.8rem !important;
        }
        & > :nth-child(2) {
          width: 18% !important;
          font-size: 0.8rem !important;
        }
        & > :nth-child(3) {
          width: 15% !important;
          font-size: 0.8rem !important;
        }
        & > :nth-child(4) {
          width: 20% !important;
          font-size: 0.8rem !important;
        }
        & > :nth-child(5) {
          width: 25% !important;
          font-size: 0.8rem !important;
        }
        td,
        th {
          padding: 5px !important;
        }
        .learnBtn {
          padding: 0.5rem 4px !important;
          border: 1px solid #e1e1e1 !important;
          border-radius: 35px !important;
          text-align: center !important;
          font-size: 0.8rem !important;
        }
      }
    }
  }
}
