.desktopWrapper {
  width: 100%;
}

.listGrid {
  background-color: #fafafa;
  border-bottom: 0.5px solid #e7e7e7;
  border-top: 0.5px solid #e7e7e7;
  display: grid;
  font-size: 0.8rem;
  height: 45px;
  padding: 10px 30px;
  grid-template-columns: 1fr 1.2fr 1fr 1fr 1fr;
}

.leftAlign {
  text-align: left;
}

.listDataGrid {
  border-bottom: 0.5px solid #e7e7e7;
  display: grid;
  height: 90px;
  padding: 10px 30px;
  grid-template-columns: 1fr 1.2fr 1fr 1fr 1fr;
}

.datafields {
  font-weight: 700;
  font-size: 20px;
  overflow: scroll;
  height: 85vh;
}

.title {
  color: #18191d;
  font-size: 0.9rem;
  font-weight: 600;
}

.navBar {
  background-color: #3e6d3f;
  height: 87px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
}

.rightContainer {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  font-size: 0.95rem;

  & > .submitBtn {
    padding: 0.6rem 1.5rem;
    border: 1px solid #e5e5e5;
    border-radius: 35px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all linear 0.2s;

    &:hover {
      scale: 1.025;
    }
  }

  & > .totalCount {
    display: flex;
    flex-direction: column;
    color: white;

    & > .title {
      font-weight: 550;
      font-size: 1.1rem;
      color: white;
    }

    & > .value {
      font-size: 450;
    }
  }

  & > .searchInput {
    padding: 0.6rem 1rem;
    border: 1px solid #e5e5e5;
    border-radius: 35px;
  }
}

.switches {
  display: flex;
  align-items: center;
}

.textdisplay {
  color: white;
  font-size: 0.95rem;
  font-weight: 550;
}

.react-switch-bg {
  border-radius: 50px;
}
