.mainGrid {
  display: flex;
  // grid-template-columns: 1fr 1fr;
  // grid-template-rows: 1fr;
  // grid-template-areas: "left right";
  height: calc(100vh - 80px);
  width: 100vw;
  flex-direction: row-reverse;
  // background: var(--main-color);
}

.loginNavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 7rem;
  background: var(--main-color);

  & > img {
    height: 75%;
  }

  & > .backBtn {
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    transition: all linear 0.3s;
    color: white;

    // &:hover {
    //   color: var(--main-color);
    // }
  }
}

.loginFooter {
  background: var(--font-color);
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2.5px;
  font-size: 0.65rem;
  font-weight: 450;
  color: white;
}

.leftSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  min-width: 40%;
  gap: 1.5rem;
  background: var(--main-color);
  height: calc(100% - 3rem);
  margin: auto 0;
  border-radius: 12px 0px 0px 12px;
}

.rightSide {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  min-width: 60%;
  flex-direction: column;
  padding: 2.5rem 2rem 0 2rem;

  & > img {
    height: 72%;
    margin: 0 auto;
    margin-top: auto;
  }
}

.leftBox {
  background: white;
  padding: 2rem 3rem;
  margin-left: auto;
  margin-right: 7rem;
  margin-top: 2rem;
  width: 400px;
  // max-height: 95%;
  border-radius: 10px;
  // aspect-ratio: 1/1.2;
  display: grid;
  // flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  overflow-y: scroll;
  max-height: calc(100% - 100px);

  & > img {
    height: 3.5rem;
  }
}

.iconDivParent {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  margin-left: auto;
  margin-right: 7rem;

  & > .iconDiv {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 7px;

    & > svg {
      font-size: 1.5rem;
      cursor: pointer;
    }
    & > img {
      width: 1.2rem;
      height: 1.2rem;
      cursor: pointer;
    }
  }

  & > .createNewAccount {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > :nth-child(1) {
      font-size: 0.9rem;
    }

    & > :nth-child(2) {
      font-size: 0.75rem;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.welcomeContainer {
  // color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .title {
    font-size: 2rem;
    font-weight: 500;
    // color: white;
    margin-top: 1rem;
  }

  & > .desc {
    font-size: 1.4rem;
    font-weight: 500;
    margin-top: 0.8rem;
    width: 65%;
    text-align: center;
  }
}

.resetPassWrap {
  position: relative;

  & > img {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.titleDesc {
  font-size: 1rem;
  font-weight: 460;
  margin: 1rem 0;
}

.labelTitle {
  margin-right: auto;
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: 430;
}

.input1 {
  width: 100%;
  // height: 7%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 7.5px;
  padding: 10px 23px;
  // margin-bottom: 1rem;
  // margin-top: 1.2rem;
  font-size: 1.9vh;
  input {
    border: none;
    width: 83%;
    &::placeholder {
      font-size: 1.9vh;
    }
  }
  .closeImg {
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
    }
  }
  .indicator {
    width: 10px;
    height: 10px;
    background: #dc3027;
    border: none;
    border-radius: 50%;
  }
  .eyeImg {
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
    }
  }
}

.conditions {
  width: 100%;
  // margin-top: 1rem;
  margin-bottom: 1rem;
  background: #fffdf2;
  border-radius: 15px;
  display: flex;
  flex-direction: column;

  .eachCondition {
    display: flex;
    width: 100%;
    padding: 10px 23px;
    align-items: center;
    justify-content: space-between;
    font-size: 0.7rem;
    font-weight: 450;
    .indicator {
      width: 10px;
      height: 10px;
      background: rgba(220, 48, 39, 0.37);
      border: none;
      border-radius: 50%;
    }
  }
}

.input2 {
  width: 100%;
  // height: 7%;
  border: 1px solid #ccc;
  border-radius: 7.5px;
  padding: 10px 23px;
  margin-bottom: 2%;
  font-size: 1.9vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    border: none;
    width: 85%;
    &::placeholder {
      font-size: 1.9vh;
    }
  }
  .eyeImg {
    cursor: pointer;
    color: var(--font-color);
    &:hover {
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
    }
  }
}

.forgotPassword {
  background: white;
  -webkit-background-clip: text;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 0.9rem;
  width: 50%;
  text-align: right;
  margin-left: auto;
  margin-top: 3px;

  div {
    color: #117df9;
    font-weight: 420;
    cursor: pointer;
    background: white;
    -webkit-background-clip: text;
    background-clip: text;
    text-fill-color: transparent;
    &:hover {
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
    }
  }
}

.checkBox {
  font-size: 0.7rem;
  font-weight: 400;
  display: flex;
  gap: 10px;
  margin-top: 1rem;
  margin-bottom: 1rem;

  & > input {
    width: 2rem;
  }

  & > div {
    & > span {
      color: #d0004b;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.loginButton {
  background: var(--main-color);
  width: 100%;
  border-radius: 7.5px;
  padding: 10px 1rem;
  color: white;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7px;
  cursor: pointer;
  font-size: 1rem;
  background: #3e6d3f;
  transition: all ease 0.2s;

  &:hover {
    transform: scale(1.05);
  }
}

.buttonDiv {
  display: flex;
  gap: 5px;
  width: 100%;
  margin-top: 1rem;
  justify-content: space-between;
  .gbBtn {
    width: 49%;
    padding: 10px 1rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
    color: var(--font-color);
    border: 1px solid #e5e5e5;
    font-weight: 400;
    margin-top: 7px;
    text-align: center;
    &:hover {
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
    }
  }
}

.loginButtonDisabled {
  background: linear-gradient(90deg, #81cbd7 0%, #3d4394 100%);
  width: 50%;
  padding: 2%;
  color: white;
  font-weight: 700;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 85px;
  cursor: pointer;
  opacity: 0.5;
  font-size: 1.9vh;
}

.googleLogin {
  width: 50%;
  padding: 2%;
  font-weight: 600;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--font-color);
  font-size: 1.9vh;
  cursor: pointer;
  transition: all ease 0.2s;

  &:hover {
    transform: scale(1.05);
  }
}

.rightlabel {
  background: linear-gradient(90deg, #3d4394 0%, #81cbd7 91.36%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
  font-size: 2vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rightCard {
  padding: 4% 4%;
  margin-top: 4%;
  border: double 1px transparent;
  border-radius: 25px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #81cbd7, #3d4394);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  height: 18%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:hover {
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
}

.rightCardnoHover {
  padding: 4% 4%;
  margin-top: 4%;
  border: double 1px transparent;
  border-radius: 25px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #81cbd7, #3d4394);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.rightCardTitle {
  background: linear-gradient(90deg, #3d4394 0%, #81cbd7 91.36%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
  font-size: 2vh;
}
.rightCardSubTitle {
  font-weight: 400;
  font-size: 1.6vh;
  color: var(--font-color);
  margin-top: 2%;
  line-height: 25px;
}
.rightCardActive {
  padding: 4% 4%;
  margin-top: 4%;
  border: double 1px transparent;
  border-radius: 25px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #81cbd7, #3d4394);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  height: 18%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:hover {
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
}

.options {
  height: 75px;
  display: flex;
  align-items: center;
  padding-left: 35px;
  border: 0.5px solid #e5e5e5;
  margin-bottom: 4%;
  border-radius: 15px;
  font-size: 1.7vh;
  &:hover {
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
}

.optionsDisabled {
  height: 75px;
  display: flex;
  align-items: center;
  padding-left: 35px;
  border: 0.5px solid #e5e5e5;
  margin-bottom: 4%;
  border-radius: 15px;
  font-size: 1.7vh;
}

// Mobile UI Styles

.mobileLogo {
  border-bottom: 1px solid #e5e5e5;
  padding: 5% 20%;
  background-color: white;
  height: 9vh;
  margin-bottom: 10vh;
  img {
    width: 100%;
  }
}

.stepContainer {
  height: 65vh;
  position: relative;
  .AffiliatePrompt {
    width: 100%;
    display: flex;
    gap: 10px;
    padding: 1rem 1.5rem;
    background: #fffdf2;
    border-radius: 15px;
    margin-bottom: 2vh;
    font-size: 0.85rem;
  }
}
.pageTitle {
  font-size: 2vh;
  font-weight: 600;
  color: var(--font-color);
  // margin-top: 10%;
  margin-bottom: 10%;
}
.optionCards {
  display: flex;
  align-items: center;
  margin-bottom: 5%;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  height: 84px;
  padding-left: 30px;
  font-size: 1.8vh;
  font-weight: 600;
  input {
    border: none;
    width: 90%;
    height: 100%;
  }
}
.buttonWrapper {
  position: absolute;
  bottom: 20px;
  width: 100%;
  // background-color: red;
}
.buttonWrapper1 {
  margin-top: 10vh;
}
.backButton {
  background-color: var(--main-color);
  border-radius: 15px;
  width: 100%;
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 20px;
}
.continueButton {
  background: var(--font-color);
  border-radius: 15px;
  width: 100%;
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 20px;
}
.loadingComponent {
  position: absolute;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: black;
  opacity: 0.4;
}
.redDot {
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
}

.greenDot {
  width: 12px;
  height: 12px;
  background-color: green;
  border-radius: 50%;
}
.passwordChecklist {
  background-color: #fffdf2;
  border-radius: 15px;
  padding: 20px;
  position: relative;

  .eachChecklist {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .indicator {
      width: 10px;
      height: 10px;
      background: rgba(220, 48, 39, 0.37);
      border: none;
      border-radius: 50%;
    }
  }
}
.checklistLabel {
  text-decoration: underline;
}
.otpInputWrapper {
  width: 75%;
  justify-content: space-between;
  > div {
    flex: 0 0 22%;
    height: 60px;
    .otpInput {
      width: 80% !important;
      height: 100%;
      border: 2px solid #e5e5e5;
      border-radius: 8px;
      font-size: 28px;
    }
  }
}

//mobile UI

.mobileMain {
  width: 100vw;
  height: 100vh;
  padding: 5rem 2rem 2rem 2rem;
  display: flex;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 60%;
    margin-top: auto;
    padding: 2rem 0;

    & > img {
      width: 80%;
      max-width: 220px;
    }

    & > .desc {
      font-size: 1.4rem;
      text-align: center;
    }
  }
}

.mobileLogin {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem;
  height: 100vh;
  max-width: 100vw;

  & > img {
    max-height: 5rem;
    object-fit: contain;
  }

  & > .loginContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;

    & > .title {
      font-size: 1.25rem;
      font-weight: 510;
      color: var(--main-color);
      margin-bottom: 3rem;
    }

    & > .inputContainer {
      position: relative;
      border: 1px solid #e7e7e7;
      border-radius: 35px;

      & > input {
        outline: none;
        border: none;
        border-radius: 35px;
        padding: 1rem 1.5rem;
        width: 100%;
        background: #f5f5f5 !important;
      }

      & > .eyeImg {
        position: absolute;
        top: 50%;
        right: 1.5rem;
        transform: translate(0%, -50%);
      }
    }

    & > .loginBtn {
      all: unset;
      border-radius: 35px;
      padding: 1rem 1.5rem;
      background: var(--main-color);
      color: white;
      font-weight: 500;
      text-align: center;
    }
  }

  & > .forgetPassword {
    text-align: center;

    & > button {
      all: unset;
      color: var(--main-color);
      font-weight: 520;
      cursor: pointer;
    }
  }

  & > .createNewAccount {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > :nth-child(1) {
      font-size: 1.1rem;
    }

    & > :nth-child(2) {
      font-size: 0.95rem;
      font-weight: 510;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 768px) {
}
